//productdetails.js
 
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Fetch_productDetails } from "../../../hooks/APIHooks";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { GlobalData } from "../../../assets/data/GlobalData";
import { useSwipeable } from "react-swipeable";

const ProductDetails = () => {
  const { productId } = useParams(); // Get productId from URL
  const navigate = useNavigate(); // Initialize useNavigate for redirection
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentImage, setCurrentImage] = useState(""); // Default to first image
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null); // Track selected variant
  const [productPrice, setProductPrice] = useState(null); // Track updated product price

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${Fetch_productDetails}?product_id=${productId}`
        );
        if (response.data.success) {
          setProduct(response.data.data.product);
          setVariants(response.data.data.variants);

          // Set the first image as the default current image
          const images = [
            response.data.data.product.product_image,
            response.data.data.product.product_image_1,
            response.data.data.product.product_image_2,
            response.data.data.product.product_image_3,
            response.data.data.product.product_image_4,
            response.data.data.product.product_image_5,
          ].filter(Boolean); // Filter out any null/undefined images
          setCurrentImage(images[0] || ""); // Set the first available image

          // Set the initial price (default product price)
          setProductPrice(response.data.data.product.price);
        } else {
          setError(response.data.error);
        }
      } catch (err) {
        setError("Error fetching product details. Please try again later.");
      }
    };

    fetchProductDetails();
  }, [productId]);

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  // Function to group variants by `variant_name`
  const groupVariantsByName = (variants) => {
    return variants.reduce((acc, variant) => {
      const name = variant.product_variant.variant_name;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(variant);
      return acc;
    }, {});
  };

  const handleVariantSelection = (variant) => {
    // Update the selected variant and set the product price to the base price + additional price
    setSelectedVariant(variant);
    setProductPrice(variant.product_variant_mapping.additional_price);
  };

  const handleAddToCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProduct = cart.find(
      (item) =>
        item.product_id === product.product_id &&
        item.variant_id === selectedVariant?.variant_id
    );

    let updatedCart;
    if (existingProduct) {
      updatedCart = cart.map((item) =>
        item.product_id === product.product_id &&
        item.variant_id === selectedVariant?.variant_id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    } else {
      updatedCart = [
        ...cart,
        {
          ...product,
          quantity: 1,
          price: productPrice, // Add the updated product price
          variant: selectedVariant
            ? `${product.product_name} ${selectedVariant.product_variant.variant_name} ${selectedVariant.variant_option.option_value}`
            : "", // Concatenate product_name, variant_name, and option_value
          variant_id: selectedVariant?.variant_id, // Store variant ID for reference
        },
      ];
    }

    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleBuyNow = () => {
    // Add product to cart with quantity 1
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const updatedCart = [
      ...cart.filter((item) => item.product_id !== product.product_id),
      { ...product, quantity: 1 },
    ];
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    // Redirect to the checkout page
    navigate("/Store/checkout");
  };

  // Collect all product images in an array
  const images = [
    product.product_image,
    product.product_image_1,
    product.product_image_2,
    product.product_image_3,
    product.product_image_4,
    product.product_image_5,
  ].filter(Boolean); // Filter out any null or undefined images

  return (
    <section>
      <Helmet>
      <title>
        {GlobalData.company.companyName} - {product.product_name}
      </title>
      <meta
        name="description"
        content="Web Development and Digital Marketing Company. We are here to build your business online. One stop for all your digital needs."
      />
      <link
        rel="canonical"
        href="/Store/sub-catalogue/products/product-details/"
      />
    </Helmet>

      <section className="mx-5">
        <div className="container mx-auto my-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <div>
              {/* Main Image */}
              <img
              src={currentImage}
              alt={product.product_name}
              className="w-full h-auto max-w-md mx-auto rounded-lg shadow-md"
            />

              {/* Thumbnail Images */}
              <div className="flex gap-4 mt-4 overflow-x-auto">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${product.product_name} - ${index}`}
                    className={`w-20 h-20 object-cover rounded-lg cursor-pointer ${
                      currentImage === image ? "ring-2 ring-blue-500" : ""
                    }`}
                    onClick={() => setCurrentImage(image)} // Set the clicked image as the currentImage
                  />
                ))}
              </div>

              <div className="flex mt-5 gap-2">
                <button
                  className="text-white bg-black font-sans font-bold text-center uppercase transition-all text-xs py-3 px-6 rounded-lg bg-blue-gray-900/10 text-blue-gray-900 hover:scale-105 focus:scale-105 active:scale-100 block w-full"
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>
                <button
                  className="text-white bg-primary font-sans font-bold text-center uppercase transition-all text-xs py-3 px-6 rounded-lg hover:scale-105 focus:scale-105 active:scale-100 block w-full"
                  onClick={handleBuyNow}
                >
                  Buy Now
                </button>
              </div>
            </div>
            <div>
              <div className="border rounded-md p-4">
                <h1 className="text-3xl font-bold mb-4">
                  {product.product_name}
                </h1>
                <p className="text-3xl font-semibold"> ₹{productPrice}</p>{" "}
                {/* Display the updated product price */}
                Stock: {product.stock_quantity}
                <p className="text-xl flex items-center justify-center rounded-full w-fit text-white mt-4 bg-green-700 px-3 py-1 space-x-1">
                  <span>{product.product_rating}</span>
                  <FaStar />
                </p>
                <p className="text-sm bg-gray-200 w-fit px-2 font-medium rounded-md text-center text-gray-600 mt-4">
                  Free Delivery
                </p>
              </div>

              <section>
                <div>
                  <h2 className="text-xl font-semibold">Available Variants:</h2>
                  {variants.length > 0 ? (
                    <div className="mt-4 space-y-6">
                      {Object.keys(groupVariantsByName(variants)).map(
                        (variantName, index) => (
                          <div
                            key={index}
                            className="p-4 border rounded-lg shadow-md bg-white"
                          >
                            {/* Title: variant name */}
                            <h3 className="text-lg font-bold mb-2">
                              {variantName}
                            </h3>

                            <div className="flex flex-wrap gap-2">
                              {groupVariantsByName(variants)[variantName].map(
                                (variant, idx) => (
                                  <div
                                    key={idx}
                                    className="p-4 border border-black rounded-full shadow-md bg-gray-50 cursor-pointer hover:bg-gray-100 transition"
                                    onClick={() =>
                                      handleVariantSelection(variant)
                                    }
                                  >
                                    <p className="font-bold text-center leading-tight w-10 h-5 flex flex-col items-center justify-center">
                                      {variant.variant_option.option_value}
                                      <p className="text-[12px]">
                                        ₹
                                        {
                                          variant.product_variant_mapping
                                            .additional_price
                                        }
                                      </p>
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <p>No variants available for this product.</p>
                  )}
                </div>

                <div className="border rounded-md p-4 mt-2">
                  <p className="text-sm font-medium rounded-md text-gray-600 mt-4">
                    {product.product_description}
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ProductDetails;
