import React from "react";
import {GlobalData} from '../assets/data/GlobalData'

const UnderConstruction = () => {
  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-photo/view-cartoon-character-shopping_23-2151656713.jpg?t=st=1737565945~exp=1737569545~hmac=eb0651fb817005f50a015bac97ac42793e982efb8b533910ae9edf85de3b95a9&w=1380')",
      }}
    >
      <div className="text-center bg-white/80 backdrop-blur-sm p-6 rounded shadow-lg max-w-md">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
        <h1 className="text-2xl font-hearthway text-[30px] text-center">{GlobalData.company.companyName}</h1>
          Under Construction
        </h1>
        <p className="text-gray-600 mb-6">
          We are currently working on something awesome. Stay tuned!
        </p>
        <div>
          <p className="text-sm text-gray-500">Developed by</p>
          <p className="text-sm font-semibold text-gray-800">
  <a 
    href="https://api.whatsapp.com/send?phone=918143407758&text=Welcome%20to%20Cyberspacedigital" 
    target="_blank" 
    rel="noopener noreferrer" 
    className="text-yellow-500 hover:underline"
  >
    Cyber Space Digital
  </a>
</p>

        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
